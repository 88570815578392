@import '../app.scss';

.layout-container {
  .page-title {
    margin-bottom: $SPACING;
  }
  .layout-wrapper {
    display: flex;
    width: 100%;
    min-height: 100vh;
    .main-layout {
      width: calc(100% - 250px);
      margin-top: $HEADER_HEIGHT;
      background-color: $BODY_COLOR;
      flex-grow: 1;
      padding: $SPACING;
    }
  }
  .MuiToolbar-root {
    min-height: $HEADER_HEIGHT;
  }
}
