@import '../app.scss';

.mentor-detail-container {
  .tab-btn {
    &.Mui-selected {
      color: $PRIMARY_COLOR;
    }
  }

  .MuiTabs-indicator {
    background-color: $PRIMARY_COLOR;
  }

  .mentors-calendar {
    width: 100%;

    .mentors-list {
      width: 100%;

      &.MuiPaper-root {
        border-top-right-radius: 4px;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
      }
    }
  }
}
