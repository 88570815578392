@mixin width-height($width, $height: $width) {
    width: $width;
    height: $height;
}

@mixin cover-background {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

@mixin flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

@mixin position-absolute {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
}