@import './app.scss';

.day-scale-cell {
  &:hover {
    background-color: $GRAY_BOX_SHADOW;
    cursor: pointer;
  }
}

//Custom Big Calendar
.rbc-date-cell {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
}

.rbc-overlay {
  width: 300px;
  max-height: 400px;
  overflow: auto;
  border-radius: $RADIUS_NORMAL;
  .rbc-event-content {
    max-height: 30px;
    min-width: 60px;
    text-overflow: ellipsis;
  }

  &::-webkit-scrollbar {
    width: 6px;
    background-color: $WHITE;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: $RADIUS_NORMAL;
    background-color: $GRAY;
  }
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: $WHITE;
  }

  .rbc-event {
    &:focus {
      outline: none !important;
    }
    .rbc-event-content {
      padding: 3px 0;
      -webkit-line-clamp: 1;
    }
  }

  .rbc-overlay-header {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 0;
    position: sticky;
    top: -10px;
    background-color: $WHITE;
    font-weight: 500;
  }
}

.rbc-event {
  font-size: $FONT_SIZE_SMALL;
}

.rbc-event-content {
  width: 100%;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  // max-height: 30px;
}

.rbc-button-link {
  font-size: $FONT_SIZE_NORMAL;
}

.rbc-header {
  font-size: $FONT_SIZE_SMALL !important;
  color: $GRAY;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
}

.rbc-time-slot {
  .rbc-label {
    font-size: $FONT_SIZE_SMALL;
    color: $GRAY;
  }
}

.rbc-date-cell {
  .rbc-button-link {
    font-size: $FONT_SIZE_SMALL;
    width: 100%;
  }
  &:hover {
    background-color: $GRAY_BOX_SHADOW;
  }
}

.rbc-button-link {
  font-size: $FONT_SIZE_SMALL;
}
