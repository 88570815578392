@import '../app.scss';

.record-video-container {
  .breadcrumb-text {
    color: $DARK_BLUE;
    &-list {
      color: $PRIMARY_COLOR;
      text-decoration: none;
    }
  }
  .record-video {
    &-title {
      padding: 12px;
      font-weight: bold;
      font-size: 18px;
    }
    &-stream {
      background-color: $LIGHT_BLACK;
      padding: 70px 23px;
      border-radius: $RADIUS_NORMAL;
      padding-bottom: 0;
      &-main {
        width: 100%;
        height: 683px;
      }
      &-participants {
        height: 150px;
        width: 100%;
        position: relative;
        @include cover-background;
        span {
          text-align: center;
          background: $BLUR_BLACK;
          color: $WHITE;
          font-size: $FONT_SIZE_SMALL;
          padding: 4px;
          height: 25px;
          max-width: 60px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      &-control {
        color: white;
        background: $BLUR_DARK_BLUE;
        width: 60%;
        margin: 0 auto;
        padding: 0 20px 20px 20px;
        .controller-volume {
          padding: 8px;
          background: $BLUR_GRAY;
          border-radius: 8px;
          border: 1px solid rgba(75, 85, 99, 0.5);
          height: 50px;
          width: 200px;
        }
        .controller-play {
          svg {
            color: $WHITE;
            font-size: 40px;
          }
        }
      }
      .stream-participants-wrapper {
        overflow: auto;
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
        &::-webkit-scrollbar {
          display: none; /*Chrome, Safari and Opera */
        }
      }
    }
  }
}
