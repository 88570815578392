.page-loading {
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10100;
  &:after {
    content: '';
    display: block;
    position: fixed;
    width: 100%;
    height: 100%;
    background: $LIGHT_WHITE;
    z-index: -1;
  }
}

.select-with-icon {
  .btn-filter {
    padding: 0 2rem;
    min-width: 228px;
    justify-content: space-between;
    border: 0.5px solid #00afbb;
    border-radius: 8px;
    color: #00afbb;
    span {
      margin: 0 1rem;
    }
  }
}

/**** Landing Modal ****/
.MuiModal-root {
  .modal-content {
    border-radius: $RADIUS_LARGE;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 15px;
    background: $WHITE;
    .title {
      font-weight: bold;
      color: $PRIMARY_COLOR;
      font-size: $FONT_SIZE_LARGE;
    }
    .MuiButton-textSizeMedium {
      height: 0px;
    }
  }
}

/**** Data Grid Table ****/
.table-using-mui {
  .MuiDataGrid-columnHeaders {
    background-color: $LIGHT_BLUE;
    .MuiDataGrid-columnHeaderTitle {
      font-weight: bold;
    }
  }
  .MuiDataGrid-row,
  .MuiDataGrid-root .MuiDataGrid-cell,
  .rendering-zone {
    max-height: none !important;
  }
  .MuiDataGrid-root .MuiDataGrid-window {
    position: relative !important;
  }
  .MuiDataGrid-root .MuiDataGrid-viewport {
    max-height: none !important;
  }
  .MuiDataGrid-cell {
    white-space: normal !important;
  }
  .table-data-grid {
    .MuiDataGrid-iconButtonContainer {
      visibility: visible;
      width: 0 !important;
    }
    .MuiDataGrid-columnHeaderTitle {
      line-height: 1.43rem;
      white-space: normal;
    }
    .MuiDataGrid-columnHeaderTitleContainerContent {
      width: 70%;
    }
  }
}

/**** Search Input ****/
.search-input {
  .MuiOutlinedInput-root {
    border-radius: $RADIUS_LARGE;
  }
}

/**** Antd Table ****/
.ant-table-pagination.ant-pagination {
  margin: 16px;
}
.ant-table-thead {
  .time-range {
    padding: 16px 4px !important;
  }
  .ant-table-cell {
    background-color: $LIGHT_BLUE;
  }
}

.text-field-require {
  label.Mui-disabled::after {
    content: '';
  }
  label::after {
    content: ' *';
    color: red;
  }
}

.text-field-require-checkbox {
  label.Mui-disabled::after {
    content: '';
  }
  label.label-main::after {
    content: ' *';
    color: red;
  }
}

.checkbox-service {
  max-height: 85px;
  flex-wrap: wrap;
  justify-content: start;
  overflow-x: auto;
}

.ant-btn-link,
.ant-btn-link:hover,
.ant-btn-link[disabled] {
  border: 1px solid $OUTLINE;
  margin-right: 3px;
}

// MUI CUSTOM CHECKBOX
.mui-custom-checkbox {
  display: flex;
  justify-content: center;
  position: relative;
  input {
    outline: 0;
    visibility: hidden;
    width: 1.25em;
    margin: 0;
    font-size: inherit;
    position: absolute;
    top: 0;
    left: 0;
  }
  input:checked + span:after {
    transform: translate(0.5em, 0.3365384615em) rotate(-45deg);
    width: 0.76em;
    height: 0.38em;
    border: 0.145em solid $WHITE;
    border-top-style: none;
    border-right-style: none;
  }
  input:checked + span:before {
    background: $PRIMARY_COLOR;
    border: none;
  }
  > span {
    clear: both;
    &::before {
      content: '';
      width: 1.25em;
      height: 1.25em;
      background: $WHITE;
      border: 2px solid rgba(0, 0, 0, 0.54);
      border-radius: 0.125em;
      cursor: pointer;
      display: block;
    }
    &::after {
      content: '';
      position: absolute;
      left: 0;
      top: 1px;
    }
  }
}

html {
  scroll-behavior: smooth;
}

.custom-filter-dropdown {
  .radio-items {
    max-height: 320px;
    max-width: 300px;
    overflow-y: auto;
    overflow-x: hidden;
    .radio-item {
      padding: 5px 10px;
      width: 100%;
      span:nth-child(2) {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    .radio-item:hover {
      background-color: #f0f0f0;
    }
  }
  .custom-filter-buttons {
    border-top: 1px solid #f0f0f0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 8px;

    button:not([disabled]).custom-reset-button {
      color: #40a9ff;
    }
    .custom-reset-button:hover {
      border: 1px solid #d9d9d9;
    }
  }
}
