@import '../app.scss';

.header-container {
  .logo-wrapper {
    width: 250px;
    margin-left: $SPACING * -1;
    @media only screen and (min-width: 960px) and (max-width: 1280px) {
      width: 100px !important;
    }

    .logo-image {
      max-height: 60px;
      margin-left: 30px;
    }

    .logo-item {
      display: flex;
      align-items: flex-end;
      align-self: center;

      .MuiSvgIcon-root {
        width: auto;
        height: auto;
        padding-left: 2px;
      }
    }
  }

  .header-action {
    .MuiOutlinedInput-root {
      .MuiOutlinedInput-notchedOutline {
        border: none;
      }
    }
  }

  .user-wrapper {
    color: $DARK_BLUE;
  }
}

.notice-title {
  color: $PRIMARY_COLOR;
}

.notice-items {
  .notice-content {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.breadcrumb-text {
  color: $DARK_BLUE;
  &-list {
    color: $PRIMARY_COLOR;
    text-decoration: none;
  }
}
