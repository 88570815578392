@import '../app.scss';

.job-container {
  .btn-set-to-box {
    border-color: $YAM;
    color: $YAM;
  }
  
  .ellipsis-text {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .balance {
    background: linear-gradient(to left, #36d1dc, #5b86e5);
    border-radius: $RADIUS_LARGE;
    color: $WHITE;
    font-weight: 500;
    .date {
      font-weight: bold;
    }
  }
  .list-service {
    .service-item {
      span {
        font-weight: bold;
        font-size: $FONT_SIZE_LARGE;
      }
    }
    .service-details {
      max-height: 600px;
      width: 98%;
      overflow: auto;
      padding: 0px 8px;
      margin: 16px 8px;
      margin-top: 0;
      &::-webkit-scrollbar {
        width: 10px;
      }
      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px $WHITE;
      }
      &::-webkit-scrollbar-thumb {
        background: $YAM;
        border-radius: $RADIUS_SMALL;
      }
      .service-detail {
        font-weight: 500;
        color: $YAM;
      }
      .service-desc-rating {
        svg {
          color: $SKIN;
          margin-right: 5px;
        }
      }
      .service-desc {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        width: 100%;
        max-height: 50px;
      }
    }

    .btn-reservations {
      background: linear-gradient(224.68deg, #20c8c8 0%, #27a1fc 99.45%);
      font-weight: 500;
      width: 100%;
      color: $WHITE;
    }
  }
  .table-container {
    .MuiTableHead-root {
      background-color: $LIGHT_BLUE;
      .MuiTableCell-root {
        font-weight: bold;
        width: calc(100% / 12);
      }
    }
    .MuiTableBody-root {
      .MuiTableRow-root:nth-of-type(even) {
        background-color: $LIGHT_GRAY;
      }
    }
  }
  .table-friend-request {
    .btn-reject,
    .btn-approval {
      color: $WHITE;
      padding: 0.5rem;
    }
    .btn-reject {
      background-color: $BLACK;
    }
    .btn-approval {
      background-color: $PRIMARY_COLOR;
    }
    .btn-approval:disabled {
      background-color: $DARK_GRAY;
    }
    .btn-reject:disabled {
      background-color: $DARK_GRAY;
    }
  }
  .reservation-container {
    button {
      font-weight: bold;
    }
  }
  .reservation-filter-red,
  .reservation-filter-green,
  .reservation-filter-skin,
  .reservation-filter-yam {
    color: $WHITE;
    border-radius: 50px;
    text-align: center;
    height: 40px;
  }
  .reservation-filter-red {
    background: $RED;
  }
  .reservation-filter-green {
    background: $GREEN;
  }
  .reservation-filter-skin {
    background: $SKIN;
  }
  .reservation-filter-yam {
    background: $YAM;
  }
  .icon-videocam {
    color: $YAM;
  }
  .icon-play {
    color: $PRIMARY_COLOR;
  }
}
.dialog-friend-request {
  .MuiPaper-root {
    background-color: $LIGHT_GRAY;
    max-width: unset;
    width: 665px;
    .btn-cancel,
    .btn-ok {
      color: $WHITE;
      width: 10rem;
      padding: 0.5rem;
    }
    .btn-cancel {
      background-color: $BLACK;
    }
    .btn-ok {
      background-color: $PRIMARY_COLOR;
    }
  }
}
