/******** Buttons *******/
.MuiButton-root {
  &.MuiButton-containedPrimary,
  &.MuiButton-containedPrimary:hover,
  &.MuiButton-containedPrimary:focus {
    background-color: $PRIMARY_COLOR;
  }

  &.MuiButton-containedSecondary,
  &.MuiButton-containedSecondary:hover,
  &.MuiButton-containedSecondary:focus {
    background-color: $SECOND_COLOR;
  }

  &.MuiButton-containedError,
  &.MuiButton-containedError:hover,
  &.MuiButton-containedError:focus {
    background-color: $ERROR_COLOR;
  }

  &.MuiButton-containedSuccess,
  &.MuiButton-containedSuccess:hover,
  &.MuiButton-containedSuccess:focus {
    background-color: $GREEN;
  }

  &.MuiButton-sizeMedium {
    height: 50px;
  }
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

/******  TEXT FIELD  ******/
#root {
  .MuiFormControl-root {
    width: 100%;
    color: $GRAY;

    &:disabled,
    &.disabled {
      background-color: $LIGHT_GRAY;
      opacity: 0.5;
      cursor: not-allowed;
    }

    .MuiOutlinedInput-notchedOutline {
      border: none;
      box-shadow: $GRAY_BOX_SHADOW 0px 7px 29px 0px;
    }

    input {
      background-color: $WHITE;
    }
  }
}

/******  LINK  ******/
.MuiLink-root {
  text-decoration: none;
  &:hover {
    text-decoration: none;
    color: $BLUE;
  }
}

/******  Checkbox  ******/
.MuiCheckbox-colorPrimary {
  color: $PRIMARY_COLOR;
}

.checkbox-primary {
  color: $PRIMARY_COLOR;
  cursor: pointer;
}