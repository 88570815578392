@import '../app.scss';

.service-detail-container {
  .breadcrumb-text {
    color: $DARK_BLUE;
    &-list {
      color: $PRIMARY_COLOR;
      text-decoration: none;
    }
  }
  .service-detail {
    &-title {
      margin-left: 75px;
      font-weight: bold;
      font-size: 18px;
      padding: 12px 0;
    }
    &-detail {
      padding: 30px 75px;
      .detail-title {
        font-weight: 500;
      }
      .detail-content {
        color: $GRAY;
        .MuiFilledInput-root {
          padding: 12px;
        }
      }
    }
  }
  .column-time {
    @media only screen and (min-width: 960px) and (max-width: 1280px) {
      width: 100px !important;
    }
  }
  .avatar-upload {
    img {
      object-fit: contain;
    }
  }
}
