@import '../app.scss';
.mentor-container {
  padding: 20px;
  min-height: 500px;
  .MuiTabs-indicator {
    background-color: $YAM;
    height: 3px;
  }

  .table-container {
    .MuiTableHead-root {
      background-color: $LIGHT_BLUE;
      .MuiTableCell-root {
        font-size: $FONT_SIZE_LARGE;
        font-weight: bold;
      }
    }
  }

  .search-input {
    .MuiInputBase-root {
      border-radius: $RADIUS_LARGE;
    }
  }
}
