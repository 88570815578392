@import '../../app.scss';

.main-fitness {
    .MuiAppBar-root {
        color: $PRIMARY_COLOR;
        background-color: $WHITE;
        .logo-wrapper {
            width: 10%;
            .logo-image {
                width: 100%;
                max-height: 60px;
            }
        }
    }
}
