@import '../app.scss';
.landing-container {
  height: 70vh;
  .background-landing {
    background: url(../../images/background-landing.png);
    @include width-height(100%, 100%);
    @include cover-background;
  }
  .landing-overlay {
    .landing-title {
      position: absolute;
      top: 30%;
      left: 10%;
      span {
        font-weight: 700;
        font-size: 100px;
      }
    }
    .test-easy {
      max-width: 500px;
      font-weight: 700;
    }
  }
  .landing-contact-form {
    background-color: $YARM_LIGHT;
    padding: 60px;
  }
  .table-cell {
    background-color:  $YARM_LIGHT;
  }
}

.MuiCheckbox-root {
  .MuiCheckbox-colorError {
    color: $RED;
  }
}

.primary-color {
  color: $PRIMARY_COLOR;
}

.message-body {
  .MuiOutlinedInput-root {
    background-color: $WHITE;
  }
}
