@import '../app.scss';

.training-container {
  .breadcrumb-text {
    color: $DARK_BLUE;
    &-list {
      color: $PRIMARY_COLOR;
      text-decoration: none;
    }
  }
  .training {
    &-title {
      padding: 12px;
      font-weight: bold;
      font-size: 18px;
    }
    &-stream {
      background-color: $LIGHT_BLACK;
      padding: 23px;
      border-radius: $RADIUS_NORMAL;
      padding-bottom: 0;
      &-main {
        width: 100%;
        height: 683px;
        video {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
      &-participants {
        min-height: 150px;
        width: 100%;
        // background-image: url('https://i.imgur.com/r2kwvGr.png');
        background-repeat: no-repeat;
        background-size: cover;
        video {
          width: 100%;
          height: 100%;
          object-fit: fill;
        }
        &-info {
          position: absolute;
          bottom: 5px;
          .MuiBox-root {
            background: $BLUR_BLACK;
            color: $WHITE;
            font-size: $FONT_SIZE_SMALL;
            padding: 4px;
            height: 25px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
      &-control {
        color: white;
        background: $BLUR_DARK_BLUE;
        width: 60%;
        margin: 0 auto;
        .controller-volumn,
        .controller-mic {
          margin: 23px;
          padding: 8px;
          background: $BLUR_GRAY;
          border-radius: 8px;
          border: 1px solid rgba(75, 85, 99, 0.5);
          height: 50px;
        }
        .controller-volumn {
          width: 120px;
        }
      }
      .stream-participants-wrapper {
        overflow: auto;
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
        &::-webkit-scrollbar {
          display: none; /*Chrome, Safari and Opera */
        }
      }
    }
    &-remaining-time {
      color: $WHITE;
      margin-bottom: 1rem;
      svg {
        color: $PRIMARY_COLOR;
      }
      .times-up {
        color: $ERROR_COLOR;
        -webkit-animation: flickerAnimation 1s infinite;
        -moz-animation: flickerAnimation 1s infinite;
        -o-animation: flickerAnimation 1s infinite;
        animation: flickerAnimation 1s infinite;
      }
      @keyframes flickerAnimation {
        0% {
          opacity: 1;
        }
        50% {
          opacity: 0;
        }
        100% {
          opacity: 1;
        }
      }
      @-o-keyframes flickerAnimation {
        0% {
          opacity: 1;
        }
        50% {
          opacity: 0;
        }
        100% {
          opacity: 1;
        }
      }
      @-moz-keyframes flickerAnimation {
        0% {
          opacity: 1;
        }
        50% {
          opacity: 0;
        }
        100% {
          opacity: 1;
        }
      }
      @-webkit-keyframes flickerAnimation {
        0% {
          opacity: 1;
        }
        50% {
          opacity: 0;
        }
        100% {
          opacity: 1;
        }
      }
    }
  }
}

.box-service-wrapper {
  position: relative;
  border-radius: $RADIUS_LARGE;
  &:before {
    content: '';
    display: block;
    background-color: $BLUR_BLACK;
    border-radius: $RADIUS_LARGE;
    padding-top: 100%;
  }
  .box-service {
    position: absolute;
    top: 2%;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    width: 96%;
    height: 96%;
    padding: 5px;
    border-radius: $RADIUS_LARGE;
    @include cover-background;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    background-color: $BLUR_DARK_BLUE;
  }
  .box-service {
    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: $RADIUS_LARGE !important;
    }
  }
}
