/******* Typography ********/
h1 {
    font-size: 36px;
}

h2 {
    font-size: 30px;
}

h3 {
    font-size: 24px;
}

h4 {
    font-size: 18px;
}

/******** Rounded ********/
.rounded-10 {
    border-radius: 10px;
}

.rounded-5 {
    border-radius: 5px;
}
