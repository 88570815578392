@import '../app.scss';

.sidebar-container {
  position: relative;

  .left-wrapper {
    margin-top: $HEADER_HEIGHT;
    box-sizing: border-box;
    @media only screen and (min-width: 960px) and (max-width: 1280px){
      width: 190px !important
    }

    .sidebar-list {
      display: flex;
      flex-direction: column;
      gap: 15px;
      width: 100%;
      max-width: 360px;
      padding: 20px;
    }

    .list-item {
      height: 52px;
      border-radius: 8px;
      color: $DARK_BLUE;

      &.Mui-selected .MuiTypography-root {
        font-weight: bold;
        color: $WHITE;
      }

      &.Mui-selected .MuiSvgIcon-root {
        color: $WHITE;
      }

      &.Mui-selected {
        background-color: $PRIMARY_COLOR;

        &:hover {
          background-color: $PRIMARY_LIGHT_COLOR;
        }
      }

      .primary {
        color: $DARK_BLUE;
      }
    }

    .list-item-sub-menu {
      height: 52px;
      border-radius: 8px;
      color: $DARK_BLUE;

      &.Mui-selected .MuiTypography-root {
        font-weight: bold;
        color: $PRIMARY_COLOR;
      }

      &.Mui-selected .MuiSvgIcon-root {
        color: $PRIMARY_COLOR;
      }
    }
  }

  .right-wrapper {
    background-color: $BODY_COLOR;

    .sticky-button {
      position: sticky;
      top: 300px;
      margin-left: 5px;
      opacity: 0.8;
    }
  }
}
